/* You can add global styles to this file, and also import other style files */
@use "sass:map";
@import "/src/variables.scss";

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: map.get($grey-palette, 800);
}
body * {
  box-sizing: border-box;
}
input,
textarea {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.app-input {
  padding: 6px 12px;
  border-radius: 20px;
  border: solid 1px map.get($grey-palette, 100);
  outline: none;
  transition: 300ms;

  &::placeholder {
    color: map.get($grey-palette, 300);
    opacity: 1; /* Firefox */
  }

  &:hover {
    border: solid 1px map.get($grey-palette, 300);
  }

  &:focus,
  &:active {
    border: solid 1px map.get($grey-palette, 500);
  }
}

::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: white !important;
}

::ng-deep .mat-form-field-underline {
  /*change color of underline*/
  background-color: white !important;
}

::ng-deep .mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: white !important;
}

::ng-deep .mat-form-field-label {
  /*change color of label*/
  color: white !important;
}

.repeat-week {
  display: flex;
  gap: 12px;

  .week-checkbox {
    border-radius: 50%;
    height: 28px;
    width: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: solid 1px map.get($grey-palette, 400);
    color: map.get($grey-palette, 400);

    &:hover {
      background-color: map.get($primary-palette, 100);
      color: white;
      border: none;
    }

    &.checked {
      color: white;
      background-color: map.get($primary-palette, 400);
      border: none;
    }
  }
}

.display-row-detail {
  margin-bottom: 5%;
  display: flex;
  gap: 1.5vw;
  align-items: center;

  .content {
    flex-grow: 1;

    .content-title {
      font-size: 13px;
      margin-bottom: 8px;
      color: map.get($grey-palette, 400);
    }
  }
}

.hide-mobile {
  display: none!important;
}
